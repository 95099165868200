var prodcat = prodcat || {};
// eslint-disable-next-line no-use-before-define
var pwr = pwr || '';

prodcat.data = prodcat.data || {};
var site = site || {};

site.template = site.template || {};

Drupal.behaviors.customkitsproductquickshop = (function ($) {
  var lastBP;
  var qsContainerActiveClasses = (function () {
    var classes = 'active';

    for (var i = 1; i <= 10; i++) {
      classes += ' active--' + i;
    }

    return classes;
  })();
  // Modernizr.touch = true;
  var behavior = {
    $grids: $(),
    initGrid: function ($grids, size) {
      if (_.isUndefined(size)) {
        size = Unison.fetch.now().name;
      }
      var isMobile = !_.isUndefined(size) && size.indexOf('small') !== -1;
      var self = this;

      // Insert quickshop placeholder divs into product grids.
      // On mobile there is a quickshop placeholder after each product thumb.
      // On desktop there's a shared one after each row of product thumbs.

      $grids.has('.product-grid__item').each(function () {
        // First remove any existing quickshop wrappers in the grid.
        self.reset($(this));

        var i = 0;
        var classes = '';
        var rows = [];
        var isCarousel = $(this).hasClass('product-grid--carousel');
        var $row = $();
        var $container = $('<div class="js-quickshop-container quickshop-wrapper" />');
        var $items = $('.product-grid__item:visible', this);
        var iLen = $items.length;

        $items.each(function (index) {
          var prodID = $(this).data('product-id');

          if (prodID) {
            classes += ' js-quickshop-container--' + $(this).data('product-id');
          }

          // For carousels, we just need all the classes
          if (isCarousel) {
            return;
          } else if (isMobile) {
            // For mobile simply add the quickshop wrapper immediately following
            // the item:
            $(this).after($container.clone()).next().addClass(classes);
            classes = '';
            rows.push($(this));
          } else {
            // For desktop add a new wrapper after every row of appendAfter items:
            var span = 1;
            var appendAfter = 3;

            if ($(this).data('quickshop-after')) {
              appendAfter = $(this).data('quickshop-after');
            }
            if ($(this).data('colspan')) {
              span = $(this).data('colspan');
            }
            i += span;
            $row = $row.add(this);
            if (i === appendAfter || index + 1 === iLen) {
              if (i === appendAfter) {
                $(this).addClass('last'); // css targets this rather than nth-of-type
              }
              $(this).after($container.clone()).next().addClass(classes);
              rows.push($row);
              i = 0;
              $row = $();
              classes = '';
            }
          }
        });

        if (isCarousel) {
          // slick wraps our $items in its own stuff so we have to treat those
          // as items if they've been set.
          var $slides = $('.slick-slide', this);

          rows = $slides.length ? [$slides] : [$items];
          $(this).after($container.clone()).next().addClass(classes);
        }

        $(this).data('product-grid-rows', rows);
        self.$grids.add($(this));
      });
    },

    reset: function ($grid) {
      var $container = $grid.hasClass('product-grid--carousel') ? $grid.parent() : $grid;

      $('.js-quickshop-container', $container).remove();
      $('.product-brief', $grid).removeClass('active');
    },

    attach: function (context) {
      // These use Unison, and jQuery fires its ready event before Unison, so
      // use an interval to wait till it's ready.
      var size = Unison.fetch.now() ? Unison.fetch.now().name : 'medium';
      var intvl;
      var self = this;

      this.initGrid($('.product-grid--quickshop', context), size);
      if (!Unison.fetch.now()) {
        intvl = setInterval(function () {
          if (Unison.fetch.now()) {
            size = Unison.fetch.now().name;
            self.initGrid($('.product-grid--quickshop', context), size);
            clearInterval(intvl);
          }
        }, 50);
      }
    }
  };

  $(document).on('click', '.js-customkits-quickshop-trigger', function (event) {
    event.preventDefault();
    $(this).closest('.js-product').trigger('customkits.product.quickshop.launch');
  });

  // Global events (only bound once when the script loads):
  $(document).on('customkits.product.quickshop.close', '.js-product', function () {
    var productID = $(this).data('product-id');
    var $grid = $(this).closest('.product-grid');
    var $wrapper = $grid.closest('.product-grid-wrapper');
    var $container = $('.js-quickshop-container--' + productID, $wrapper);

    $(this).removeClass('active');
    $('html').removeClass('no-scroll');

    $container.slideUp(400, function () {
      $(this).removeClass(qsContainerActiveClasses).find('.js-quickshop').remove();
      $grid.trigger('product.quickshop.afterAnimation');
    });
  });

  $(document).on('customkits.product.quickshop.launch', '.js-product', function () {
    var productID = $(this).data('product-id');
    var product = prodcat.data.getProduct(productID);
    var $product = $(this);
    var $grid = $product.closest('.product-grid');
    var isCarousel = $grid.hasClass('product-grid--carousel');
    var $gridItem = $product.closest('.product-grid__item');
    var $products = $('.product-brief', $grid);
    var $qsContext = isCarousel ? $grid.parent() : $grid;
    var $containers = $('.js-quickshop-container', $qsContext);
    var $container = $containers.filter('.js-quickshop-container--' + productID);
    var $active = $containers.filter('.active');
    var $qsProduct = $();
    var alreadyActive = $product.hasClass('active');
    var speed = 400;
    var isMobile = Unison.fetch.now().name === 'small';
    // @TODO: mobile appears to not have quickshop triggers on the MPP by design.
    // Check that's correct.
    // var template = 'product_quickshop';
    var template = isMobile ? 'custom_kits_product_quickshop' : 'custom_kits_product_quickshop';
    // Used to calculate quickshop scroll
    var qsTop = 0;
    var qsOffset = -200;

    // If already active, close and return.
    if (alreadyActive) {
      $product.trigger('customkits.product.quickshop.close');

      return;
    }

    // If the product has data-available-skus set that means to only display the
    // skus available in that attribute.
    var availableSkusString = $product.data('available-skus');

    if (availableSkusString) {
      var skus = [];

      _.each(_.map(availableSkusString.split(','), $.trim), function (skuID) {
        _.each(product.skus, function (sku) {
          if (sku.SKU_ID === skuID) {
            skus.push(sku);
          }
        });
      });
      if (skus.length) {
        product.skus = skus;
      }
    }

    // Render quickshop with MPP custom Kit product layout
    var parent_template = 'mpp_custom_kit_product_layout_uk_v1';

    if (!_.isUndefined(site.templates[parent_template])) {
      product = $.extend({}, site.templates[parent_template].data, product);
    }

    var rendered = site.template.get({
      name: template,
      data: product
    });

    // Fire QV tagging event
    if (!_.isUndefined(window.CATEGORY_ID)) {
      $(window).trigger('MPP:productQV', [window.CATEGORY_ID + '~' + productID]);
    }

    // Populate and show the container we're activating
    $active.hide();
    $('.js-quickshop', $container).remove();
    $container.append(rendered).show();

    $qsProduct = $('.js-product', $container);
    $qsProduct.trigger('product.init');

    Drupal.behaviors.skuListSizesV1.attach($qsProduct);
    Drupal.behaviors.skuSelectShadesV1.attach();

    // When the menu changes or selecting shade, trigger skuSelect to hide the addtogift CTA
    $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
      var skuId = skuBaseId;
      var skuDataL2 = prodcat.data.getSku(skuId);

      if (skuDataL2) {
        var $addBtn = $('.js-add-to-custom-kit', $qsProduct);

        $addBtn.removeClass('hidden').data('disabled', false);
        if (skuDataL2 && !skuDataL2.isShoppable) {
          $addBtn.addClass('hidden').data('disabled', true);
        } else {
          $addBtn.data('sku-base-id', skuId);
        }
      }
    });

    // Hide Add To Bag button and display sold out message duing overlay launch before add to bag button gets clicked
    var skuID = $(this).data('sku-base-id');

    if (skuID) {
      $qsProduct.trigger('product.skuSelect', [skuID]);
    }
    var $panelHeaders = $('.customkits-accordion > h3', $container);

    $panelHeaders.not('.active-header').next().slideUp(300);
    $('.customkits-accordion h3.active-header').next().slideDown(300);

    $panelHeaders.click(function () {
      if ($(this).hasClass('active-header')) {
        $(this).next().slideUp(300);
        $(this).removeClass('active-header');
      } else {
        $(this).addClass('active-header').next().slideDown();
      }

      return false;
    });

    $('.js-quickshop-close', $container).on('click', function (event) {
      event.preventDefault();
      $product.trigger('customkits.product.quickshop.close');
    });

    qsTop = $container.offset().top;

    // Here so other scripts can do computations with the final height of the
    // grid before the animation:
    $grid.trigger('product.quickshop.beforeAnimation');

    // Scroll animation:
    $('html, body').animate(
      {
        scrollTop: qsTop + qsOffset
      },
      {
        duration: speed
      }
    );

    $products.removeClass('active');
    $product.addClass('active');

    // Review Logic
    var numericProductID = productID.replace(/[A-Za-z$-]/g, '');
    var pr_products = [];

    if (pwr && typeof Drupal.settings.power_reviews != 'undefined') {
      if (numericProductID != null) {
        var reviewsnippet_id = 'pr-reviewsnippet-' + numericProductID + '-' + new Date().getTime();
        var $reviewsnippet = $('.review-snippet', $container);

        $reviewsnippet.attr('id', reviewsnippet_id);

        if ($reviewsnippet.length && !$reviewsnippet.hasClass('reviews-processed')) {
          $reviewsnippet.addClass('reviews-processed');

          var page_id = Drupal.settings.power_reviews.page_id || numericProductID;
          var review_wrapper_url =
            (Drupal.settings.power_reviews.review_wrapper_url || '/review/create') + '?page_id=' + page_id;
          var pr_product = {
            api_key: Drupal.settings.power_reviews.api_key,
            locale: Drupal.settings.power_reviews.locale,
            merchant_group_id: Drupal.settings.power_reviews.merchant_group_id,
            merchant_id: Drupal.settings.power_reviews.merchant_id,
            page_id: page_id,
            review_wrapper_url: review_wrapper_url,
            components: {
              ReviewSnippet: reviewsnippet_id
            }
          };

          pr_products.push(pr_product);
          pwr('render', pr_products);
        }
      }
    }
    // End Review Logic

    // Determine which item is active in the row so css can position the arrow.
    // The arrow is inside of the quickshop rather than relative to the thumb
    // so that it is part of the expand/collapse animation.

    var i;

    if (isCarousel) {
      var $carousel = $('.js-product-carousel', $grid).first();
      var slideIndex = $carousel.slick('slickCurrentSlide');

      i = $gridItem.data('slick-index') - slideIndex + 1;
    } else {
      i = $gridItem.prevUntil('.js-quickshop-container').filter('.js-grid-item:visible').length + 1;
    }

    // Quickshop animation:
    // 1. If current quickshop is already active, no need to animate anything
    if ($container.hasClass('active')) {
      $container.removeClass(qsContainerActiveClasses).addClass('active active--' + i);

      return;
    }

    // 2. If there are other active quickshops, animate them closed
    $active.show().slideUp(speed, function () {
      $(this).removeClass(qsContainerActiveClasses).find('.js-quickshop').remove();
      $grid.trigger('product.quickshop.afterAnimation');
      $('html').addClass('no-scroll');
    });

    // 3. Hide quickshop and animate it open
    $container
      .addClass('active active--' + i)
      .hide()
      .slideDown(speed, function () {
        // Reset jQuery's stupid garbage:
        $(this).css({
          display: '',
          overflow: ''
        });
        $grid.trigger('product.quickshop.afterAnimation');
        $('html').addClass('no-scroll');
      });
  });

  $(document).on('grid.reflow', '.product-grid--quickshop', function () {
    behavior.initGrid($(this));
  });

  Unison.on('change', function (bp) {
    // Do nothing when toggling between large and medium.
    if ((lastBP === 'large' && bp.name === 'medium') || (lastBP === 'medium' && bp.name === 'large')) {
      return;
    }
    lastBP = bp.name;

    behavior.initGrid($('.product-grid--quickshop'), bp.name);
  });

  return behavior;
})(jQuery);
